import React, { useState, Fragment } from "react";
import "./contact-us.scss";
import { Heading } from "../Heading/Heading";
import { Button } from "../Button/Button";
import Obfuscate from "react-obfuscate";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import infoIcon from "../../images/icons/info.svg";
import mailIcon from "../../images/icons/mail.svg";
import phoneIcon from "../../images/icons/phone.svg";
import iqcIllustration from "../../images/icons/iqc-illustration.svg";

const phoneRegExp = /^[0-9 ()+-]+$/;
const schema = yup
  .object({
    email: yup
      .string()
      .email(<Trans i18nKey="contact.form.validation.email.not-valid"></Trans>)
      .required(
        <Trans i18nKey="contact.form.validation.email.required"></Trans>
      ),
    company: yup.string(),
    message: yup.string(),
    gdpr: yup
      .boolean()
      .oneOf([true], <Trans i18nKey="contact.form.validation.gdpr"></Trans>),
  })
  .required();

const ContactUs = ({ title }) => {
  const {
    register,
    handleSubmit,
    onError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const showSuccessMessage = () => {
    setIsSuccess(true);
    setTimeout(() => setIsSuccess(false), 5000);
  };

  const handleSubmitSuccessful = () => {
    setLoading(false);
    showSuccessMessage();

    reset({
      email: "",
      company: "",
      message: "",
      gdpr: false,
    });
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    axios
      .post(`${process.env.GATSBY_API_URL}/contact-form`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        handleSubmitSuccessful();
      })
      .catch((error) => {
        setIsError(true);
      });
  };

  const { t } = useTranslation();
  return (
    <>
      <div className="contact-us" id="contact">
        <img src={iqcIllustration} className="contact-us__illustration" />
        <div className="container">
          <div className="gr-12">
            <div className="contact-us__title-holder">
              <Heading level="h3" decorationDown>
                {title}
              </Heading>
            </div>
          </div>
          <div className="gr-12">
            <div className="contact-us__wrap">
              <div className="contact-us__contact-details">
                <div className="holder">
                  <div className="details">
                    <div className="details__item">
                      <div className="icon">
                        <img src={phoneIcon} alt="icon" />
                      </div>
                      <div className="additional-details">
                        <Heading level="h3">{t("contact.form.phone")}</Heading>
                        <a
                          href="tel:+41445009090"
                          target="_blank"
                          rel="noreferrer"
                          className="link"
                        >
                          +41 44 500 90 90
                        </a>
                      </div>
                    </div>
                    <div className="details__item">
                      <div className="icon">
                        <img src={mailIcon} alt="icon" />
                      </div>
                      <div className="additional-details">
                        <Heading level="h3">{t("contact.form.mail")}</Heading>
                        <Obfuscate className="link" email="contact@iqc-group.com" />
                        <br />
                        <Obfuscate className="link" email="ecrep@iqc-group.com" />
                      </div>
                    </div>
                    <div className="details__item">
                      <div className="icon">
                        <img src={infoIcon} alt="icon" />
                      </div>
                      <div className="additional-details">
                        <Heading level="h3">{t("contact.form.details.ec-rep.title")}</Heading>
                        <p className="text">IQC GmbH</p>
                        <p className="text">Im EICHGRUND 16</p>
                        <p className="text">64354 Reinheim, GERMANY</p>

                        <p className="subtitle text">PRRC - {t("contact.form.details.contact")}:</p>
                        <p className="text">{t("contact.form.details.mail")}: <Obfuscate className="link" email="ecrep@iqc-group.com" /></p>
                        <p className="text">{t("contact.form.details.phone")}: 
                          <a
                            href="tel:+491727329529"
                            target="_blank"
                            rel="noreferrer"
                            className="link"
                          >
                            +49 172 7329529
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-us__form-holder" id="contact-form">
                <div role="form" className="form">
                  <form
                    onSubmit={(e) => {
                      handleSubmit(onSubmit)(e);
                    }}
                    method="POST"
                  >
                    <div className="row">
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <label className="form__label" htmlFor="company">
                            {t("contact.form.field.company-name")}
                          </label>
                          <input
                            type="text"
                            className={errors.company && "error"}
                            {...register("company")}
                            size="40"
                          />
                          <label className="error">
                            {errors.company?.message}
                          </label>
                          {errors.company && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <label className="form__label" htmlFor="email">
                            {t("contact.form.field.email")}
                          </label>
                          <input
                            type="email"
                            className={errors.email && "error"}
                            {...register("email")}
                            size="40"
                          />
                          <label className="error">
                            {errors.email?.message}
                          </label>
                          {errors.email && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                    </div>

                    <div className="row">
                      <div className="gr-12">
                        <fieldset className="form__fieldset form__fieldset--about">
                          <label className="form__label" htmlFor="message">
                            {t("contact.form.field.message")}
                          </label>
                          <textarea
                            {...register("message")}
                            rows="4"
                          />
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="gr-12">
                        <fieldset className="form__fieldset form__fieldset--gdpr">
                          <div className="checkbox-group">
                            <input
                              type="checkbox"
                              {...register("gdpr")}
                              id="gdpr"
                            />
                            <label className="form__label" htmlFor="gdpr">
                              {t("contact.form.field.consent")}
                            </label>
                            <label className="error">
                              {errors.gdpr?.message}
                            </label>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      {isSuccess && (
                        <div className="gr-12">
                          <div className="form__notification">
                            <span>{t("contact.form.validation.sent")}</span>
                          </div>
                        </div>
                      )}
                      {isError && (
                        <div className="gr-12">
                          <div className="form__error">
                            <span>{t("contact.form.validation.error")}</span>
                          </div>
                        </div>
                      )}
                      <div className="gr-12">
                        <Button
                          type="submit"
                          submitBtn
                          primary
                          withIcon
                          loading={loading}
                          disabled={loading}
                          data-sitekey="6Ld-SrEZAAAAAD9uhrzNWBaTDuQRCTuFQnKI4z54"
                          data-callback="recaptchaCallback"
                          data-badge="inline"
                          data-size="invisible"
                          onClick={() => handleSubmit(onSubmit, onError)}
                        >
                          {loading ? (
                            <Trans i18nKey="contact.form.field.sending"></Trans>
                          ) : (
                            <Trans i18nKey="contact.form.field.button"></Trans>
                          )}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
