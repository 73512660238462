import React from "react";
import "./person-card.scss";
import { Heading } from "../Heading/Heading";
import { Button } from "../Button/Button";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import mailIcon from "../../images/icons/mail.svg";
import linkedInIcon from "../../images/icons/linkedin.svg";
import cameraIcon from "../../images/icons/camera.svg";
import ImageCustom from "../ImageCustom/ImageCustom";

export const PersonCard = ({
  name,
  position,
  description,
  emailLink,
  image,
  linkedInLink,
}) => {
  const parsedImage = getImage(image);
  return (
    <>
      <div className="person-card" role="presentation">
        <div className="person-card__holder">
          <div className="person-card__image">
            <ImageCustom
              src={image}
              alt={name}
              className="rspimg image"
            ></ImageCustom>
          </div>
          <div className="person-card__info">
            <Heading level="h3" card>{name}</Heading>
            <div className="position">{position}</div>
          </div>
          <div className="person-card__cta">
            <Button link={emailLink} buttonIcon={mailIcon} rounded />
            <Button link={linkedInLink} buttonIcon={linkedInIcon} rounded />
          </div>
          <div className="person-card__description">{description}</div>
        </div>
      </div>
    </>
  );
};
