import React from "react";
import "./strategy.scss";
import { Heading } from "../Heading/Heading";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ImageCustom from "../ImageCustom/ImageCustom";
import { Container } from "../Container/Container";
import { Column } from "../Column/Column";
import FadeDown from "../FadeDown/FadeDown";

export const OurStrategy = ({ title }) => {
  const { t } = useTranslation();

  return (
    <section className="strategy">
      <Container>
        <Column
          custom_className="no-gutter@lg"
          columns_desktop="12"
          columns_mobile="12"
          columns__tablet="12"
        >
          <div className="strategy__title-holder">
            <Heading level="h3" decorationDown>
              {title}
            </Heading>
            <p className="description"> {t("homepage.strategy.description")}</p>
          </div>
          <FadeDown>
            <div className="strategy__holder">
              <p className="title">{t("homepage.strategy.gxp-compliance")}</p>
              <ImageCustom
                src={"homepage/strategy.jpg"}
                alt={t("homepage.strategy.gxp-compliance")}
                className="image"
              ></ImageCustom>
              <ImageCustom
                src={"homepage/strategy-mobile.jpg"}
                alt={t("homepage.strategy.gxp-compliance")}
                className="image--mobile"
              ></ImageCustom>
            </div>
          </FadeDown>
        </Column>
      </Container>
    </section>
  );
};
