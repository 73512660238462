import React from "react";
import "./services.scss";
import { Heading } from "../Heading/Heading";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ImageCustom from "../ImageCustom/ImageCustom";

export const Services = ({ children, title, partnersTitle }) => {
  const { t } = useTranslation();
  return (
    <section className="services" id="expertise">
      <div className="container">
        <div className="gr-12 no-gutter@xlg">
          <div className="services__title">
            <Heading level="h3" centered decorationDown>
              {title}
            </Heading>
          </div>
          <div className="services__holder">{children}</div>
          <div className="services__partners">
            <div className="container">
              <Heading level="h3" centered decorationDown centeredMobile>
                {partnersTitle}
              </Heading>
              <div className="partners">
                <div className="partners__item">
                  <ImageCustom
                    src="partners/arxum.png"
                    alt="arxum"
                    className="partners__image"
                  ></ImageCustom>
                </div>
                <div className="partners__item">
                  <ImageCustom
                    src="partners/diligram.png"
                    alt="diligram"
                    className="partners__image"
                  ></ImageCustom>
                </div>
                <div className="partners__item">
                  <ImageCustom
                    src="partners/vispe.png"
                    alt="vispe"
                    className="partners__image"
                  ></ImageCustom>
                </div>
                <div className="partners__item">
                  <ImageCustom
                    src="partners/zazoon.png"
                    alt="zazoon"
                    className="partners__image"
                  ></ImageCustom>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
