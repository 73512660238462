import React from "react";
import "./who-section.scss";
import { Heading } from "../Heading/Heading";
import FadeDown from "../FadeDown/FadeDown";

export const WhoSection = ({ title, description, content }) => {
  return (
    <section className="who-section" id="who">
      <div className="container">
        <div className="gr-12">
          <div className="who-section__holder">
            <div className="who-section__title">
              <FadeDown>
                <Heading level="h2" decorationDown>
                  {title}
                </Heading>
              </FadeDown>
            </div>
            <div className="who-section__content">
              <FadeDown>
                <p className="subtitle">{description}</p>
                {content}
              </FadeDown>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
