import React from "react";
import "./service-card.scss";
import { Heading } from "../Heading/Heading";
import FadeDown from "../FadeDown/FadeDown";
import ImageCustom from "../ImageCustom/ImageCustom";

export const ServiceCard = ({
  title,
  description,
  icon,
}) => {

  return (
    <>
    <FadeDown>
      <div className="service-card" role="presentation">
        <div className="service-card__holder">
          <div className="service-card__icon">
            <ImageCustom
              src={icon}
              alt={title}
              className="icon"
            ></ImageCustom>
          </div>
          <div className="service-card__title">
            <Heading level="h3" smallDecorationDown>{title}</Heading>
          </div>
          <div className="service-card__description">{description}</div>
        </div>
      </div>
    </FadeDown>
    </>
  );
};
