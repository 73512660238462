import React, { useState } from "react";
import "./stats.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

export const Stats = () => {
  const { t } = useTranslation();
  const [viewPortEntered, setViewPortEntered] = useState(false);

  return (
    <section className="stats">
      <div className="container">
        <div className="stats__holder">
          <div className="item">
            <CountUp start={viewPortEntered ? null : 0} end={521} duration={2}>
              {({ countUpRef }) => (
                <VisibilitySensor
                  onChange={(isVisible) => {
                    if (isVisible) {
                      setViewPortEntered(true);
                    }
                  }}
                  active={!viewPortEntered}
                >
                  <p className="number" ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            <p className="desc">{t("homepage.stats.stat1")}</p>
          </div>
          <div className="item">
            <CountUp start={viewPortEntered ? null : 0} end={922} duration={3}>
              {({ countUpRef }) => (
                <VisibilitySensor
                  onChange={(isVisible) => {
                    if (isVisible) {
                      setViewPortEntered(true);
                    }
                  }}
                  active={!viewPortEntered}
                >
                  <p className="number" ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            <p className="desc">{t("homepage.stats.stat2")}</p>
          </div>
          <div className="item">
            <CountUp
              start={viewPortEntered ? null : 0}
              end={560}
              duration={3.2}
            >
              {({ countUpRef }) => (
                <VisibilitySensor
                  onChange={(isVisible) => {
                    if (isVisible) {
                      setViewPortEntered(true);
                    }
                  }}
                  active={!viewPortEntered}
                >
                  <p className="number" ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            <p className="desc">{t("homepage.stats.stat3")}</p>
          </div>
          <div className="item">
            <CountUp start={viewPortEntered ? null : 0} end={30} duration={4}>
              {({ countUpRef }) => (
                <VisibilitySensor
                  onChange={(isVisible) => {
                    if (isVisible) {
                      setViewPortEntered(true);
                    }
                  }}
                  active={!viewPortEntered}
                >
                  <p className="number" ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            <p className="desc">{t("homepage.stats.stat4")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};
