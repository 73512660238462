import React from "react";
import "./people-section.scss";
import FadeDown from "../FadeDown/FadeDown";

export const PeopleSection = ({ children }) => {
  return (
    <section className="people-section">
      <div className="container">
        <div className="gr-12 no-gutter@xlg">
          <FadeDown>
            <div className="people-section__holder">{children}</div>
          </FadeDown>
        </div>
      </div>
    </section>
  );
};
