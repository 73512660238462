import { graphql } from "gatsby";
import React, { Fragment } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../components/layout/layout";
import { Hero } from "../components/Hero/Hero";
import { WhoSection } from "../components/WhoSection/WhoSection";
import { PeopleSection } from "../components/PeopleSection/PeopleSection";
import { PersonCard } from "../components/PersonCard/PersonCard";
import { Stats } from "../components/Stats/Stats";
import { FeatureSection } from "../components/FeatureSection/FeatureSection";
import { Services } from "../components/Services/Services";
import { ServiceCard } from "../components/ServiceCard/ServiceCard";
import { News } from "../components/News/News";
import { OurStrategy } from "../components/OurStrategy/OurStrategy";
import ContactUs from "../components/ContactUs/ContactUs";
import FadeDown from "../components/FadeDown/FadeDown";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Homepage = () => {
  const { t } = useTranslation();

  return (
    <Layout
      pageTitle={t("homepage.hero.title")}
      description={t("homepage.hero.description")}
    >
      <Hero
        title={t("homepage.hero.title")}
        description={t("homepage.hero.description")}
        homepage
      />
      <WhoSection
        title={
          <Fragment>
            {" "}
            {t("homepage.who-is-iqc.title")}{" "}
            <strong> {t("homepage.who-is-iqc.iqc")} </strong> ?
          </Fragment>
        }
        description={
          <Fragment>
            <strong> {t("homepage.who-is-iqc.iqc")} </strong>{" "}
            {t("homepage.who-is-iqc.is")}{" "}
            <strong>
              {" "}
              {t("homepage.who-is-iqc.international-quality-consulting")}{" "}
            </strong>{" "}
          </Fragment>
        }
        content={
          <Fragment>
            <p className="desc"> {t("homepage.who-is-iqc.text1")} </p>{" "}
            <p className="desc"> {t("homepage.who-is-iqc.text2")} </p>{" "}
            <p className="desc"> {t("homepage.who-is-iqc.text3")} </p>{" "}
          </Fragment>
        }
      />
      <PeopleSection>
        <PersonCard
          name={t("homepage.people.person1.name")}
          position={t("homepage.people.person1.position")}
          image="people/dominic-konrad.jpg"
          description={t("homepage.people.person1.desc")}
          emailLink="#"
          linkedInLink="#"
          videoLink="#"
        />
        <PersonCard
          name={t("homepage.people.person2.name")}
          position={t("homepage.people.person2.position")}
          image="people/andre.jpg"
          description={t("homepage.people.person2.desc")}
          emailLink="#"
          linkedInLink="#"
          videoLink="#"
        />
        <PersonCard
          name={t("homepage.people.person3.name")}
          position={t("homepage.people.person3.position")}
          image="people/ralph-heinrich.jpg"
          description={t("homepage.people.person3.desc")}
          emailLink="#"
          linkedInLink="#"
          videoLink="#"
        />
      </PeopleSection>{" "}
      <Stats />
      <FeatureSection />
      <Services
        title={t("homepage.services.title")}
        partnersTitle={t("homepage.services.partners")}
      >
        <ServiceCard
          title={t("homepage.services.service1.title")}
          description={t("homepage.services.service1.description")}
          icon="icons/multicolor/check.png"
        />
        <ServiceCard
          title={t("homepage.services.service2.title")}
          description={t("homepage.services.service2.description")}
          icon="icons/multicolor/man-arrows.png"
        />
        <ServiceCard
          title={t("homepage.services.service3.title")}
          description={t("homepage.services.service3.description")}
          icon="icons/multicolor/computer-sheets.png"
        />
        <ServiceCard
          title={t("homepage.services.service4.title")}
          description={t("homepage.services.service4.description")}
          icon="icons/multicolor/people.png"
        />
        <ServiceCard
          title={t("homepage.services.service1.title")}
          description={t("homepage.services.service1.description")}
          icon="icons/multicolor/check.png"
        />
        <ServiceCard
          title={t("homepage.services.service2.title")}
          description={t("homepage.services.service2.description")}
          icon="icons/multicolor/man-arrows.png"
        />
        <ServiceCard
          title={t("homepage.services.service3.title")}
          description={t("homepage.services.service3.description")}
          icon="icons/multicolor/computer-sheets.png"
        />
        <ServiceCard
          title={t("homepage.services.service4.title")}
          description={t("homepage.services.service4.description")}
          icon="icons/multicolor/people.png"
        />
      </Services>
      <OurStrategy title={t("homepage.strategy.title")}> </OurStrategy>
      <ContactUs title={t("contact.form.title")}> </ContactUs>{" "}
    </Layout>
  );
};

export default Homepage;
