import React from "react";
import "./hero.scss";
import { Heading } from "../Heading/Heading";
import classNames from "classnames";
import FadeDown from "../FadeDown/FadeDown";
import heroVideoMp4 from "../../videos/hero-video-test.mp4";
import heroPoster from "../../images//homepage/hero.jpg";

export const Hero = ({ title, description, inner, homepage}) => {
  return (
    <section
      className={classNames("hero", {
        "hero--inner": inner,
        "hero--homepage": homepage,
      })}
      role="presentation"
    >
      <video className="hero__video" poster={heroPoster} loop autoPlay muted>
        <source src={heroVideoMp4} type="video/mp4" />
      </video>

      <div className="hero__text-holder">
        <FadeDown>
          <Heading level="h1">{title}</Heading>
          <p className="description">{description}</p>
        </FadeDown>
      </div>
    </section>
  );
};
