import React from "react";
import "./feature-section.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ImageCustom from "../ImageCustom/ImageCustom";

export const FeatureSection = () => {
  const { t } = useTranslation();
  return (
    <section className="feature-section" id="services">
      <div className="feature-section__holder">
        <div className="feature-card">
          <div className="feature-card__image-holder">
            <ImageCustom
              src="homepage/features/expert-consulting.jpg"
              alt={t("homepage.features.feature1.title")}
              className="feature-card__image"
            ></ImageCustom>
            <div className="title-mobile">
              {t("homepage.features.feature1.title")}
            </div>
          </div>
          <div className="feature-card__body">
            <div className="feature-card__title">
              {t("homepage.features.feature1.title")}
            </div>
            <div className="feature-card__text">
              {t("homepage.features.feature1.description")}
            </div>
          </div>
        </div>
        <div className="feature-card">
          <div className="feature-card__image-holder">
            <ImageCustom
              src="homepage/features/eu-authorized-representative.jpg"
              alt={t("homepage.features.feature2.title")}
              className="feature-card__image"
            ></ImageCustom>
            <div className="title-mobile">
              {t("homepage.features.feature2.title")}
            </div>
          </div>
          <div className="feature-card__body">
            <div className="feature-card__title">
              {t("homepage.features.feature2.title")}
            </div>
            <div className="feature-card__text">
              {t("homepage.features.feature2.description")}
            </div>
          </div>
        </div>
        <div className="feature-card">
          <div className="feature-card__image-holder">
            <ImageCustom
              src="homepage/features/expert-webinars.jpg"
              alt={t("homepage.features.feature3.title")}
              className="feature-card__image"
            ></ImageCustom>
            <div className="title-mobile">
              {t("homepage.features.feature3.title")}
            </div>
          </div>
          <div className="feature-card__body">
            <div className="feature-card__title">
              {t("homepage.features.feature3.title")}
            </div>
            <div className="feature-card__text">
              {t("homepage.features.feature3.description")}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
